<template>
  <v-skeleton-loader
    type="image"
    class="identification"
    v-if="!account"
  />
  <v-card
    class="identification"
    v-else
  >
    <v-card-title>{{$t('t.Identification')}}</v-card-title>
    <v-card-text>
      <v-col>
        <v-row>
          <v-col>
            <v-text-field
              :label="$t('t.Name')"
              v-model="name"
              :rules="[...required, ...maxLength]"
            />
          </v-col>
          <v-col>
            <v-text-field
              :label="$t('t.Number#')"
              disabled
              :value="account.number"
            />
          </v-col>
          <v-col>
            <picker
              document-types="work-queues"
              :clearable="false"
              :rules="required"
              :label="$t('t.WorkQueue')"
              :selected-id.sync="workQueueId"
              :selected-document.sync="selectedWorkQueue"
              class="mt-5"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <picker
              document-types="business-divisions"
              :clearable="false"
              :rules="required"
              :label="$t('t.BusinessDivision')"
              :selected-id.sync="businessDivisionId"
              :disabled="!$store.getters.currentUserHasPermission('AccountCanChangeDivision')"
              class="mr-2"
            />
          </v-col>
          <v-col>
            <picker
              :clearable="false"
              :rules="required"
              :label="$t('t.CollectionSegment')"
              :selected-id.sync="collectionSegmentId"
              document-types="collection-segments"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <user-picker
              :selection.sync="mainSalesRep"
              :filters="{ roles: this.salesRoles }"
              :readonly="!$store.getters.currentUserHasPermission('AccountEdit')"
              document-types="users"
            >
              <template v-slot:prepend>
                <span class="text-caption">{{$t('t.MainSalesRep')}}</span>
              </template>
            </user-picker>
          </v-col>
          <v-col>
            <user-picker
              :readonly="!$store.getters.currentUserHasPermission('AccountEdit')"
              :selection.sync="secondSalesRep"
              :filters="{ roles: this.salesRoles }"
              document-types="users"
            >
              <template v-slot:prepend>
                <span class="text-caption">{{$t('t.SecondSalesRep')}}</span>
              </template>
            </user-picker>
          </v-col>
          <v-col v-show="workQueueAssigned.length">
            <div class="d-flex flex-column">
              <span class="text-caption">{{$t('t.AssignedAt')}}</span>
              <div class="d-flex gap">
                <document-ref-list-item
                  v-for="item in workQueueAssigned"
                  class="my-1"
                  :key="item.id"
                  :item="item"
                  chip
                  dense
                  readonly
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-card-text>
  </v-card>
</template>
<script>
import IncExc from '@/pages/search/controllers/inc-exc'

export default {
  components: {
    DocumentRefListItem: () => import('@/components/document-ref-list-item'),
    Picker: () => import('@/components/picker'),
    UserPicker: () => import('@/components/user-picker')
  },
  data () {
    return {
      selectedWorkQueue: null,
      salesRoles: new IncExc().include(['sales-reps']),
      usersRoles: new IncExc().include(['users']),
      maxLength: [v => !v || v.length <= 255 || this.$t('t.MaxLength', { value: 255 })],
      required: [v => (!!v && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')]
    }
  },

  computed: {
    name: {
      get () {
        return this.account.name
      },
      set (name) {
        this.$emit('update:account', Object.assign({}, this.account, { name }))
      }
    },
    mainSalesRep: {
      get () {
        return this.account.mainSalesRepId ? { id: this.account.mainSalesRepId } : null
      },
      set (user) {
        this.$emit('update:account', Object.assign({}, this.account, { mainSalesRepId: user?.id ?? null }))
      }
    },
    secondSalesRep: {
      get () {
        return this.account.secondSalesRepId ? { id: this.account.secondSalesRepId } : null
      },
      set (user) {
        this.$emit('update:account', Object.assign({}, this.account, { secondSalesRepId: user?.id ?? null }))
      }
    },
    collectionSegmentId: {
      get () {
        return this.account.collectionSegmentId
      },
      set (id) {
        this.$emit('update:account', Object.assign({}, this.account, { collectionSegmentId: id }))
      }
    },
    businessDivisionId: {
      get () {
        return this.account.businessDivisionId
      },
      set (id) {
        this.$emit('update:account', Object.assign({}, this.account, { businessDivisionId: id }))
      }
    },
    workQueueId: {
      get () {
        return this.account.workQueueId
      },
      set (id) {
        this.$emit('update:account', Object.assign({}, this.account, { workQueueId: id }))
      }
    },
    workQueueAssigned () {
      return this.selectedWorkQueue?.assigned ?? []
    }
  },
  props: {
    account: Object
  }
}
</script>
<style lang="stylus" scoped>
.v-card__text
  display flex
  flex-direction row
  flex-wrap wrap

.v-text-field
  margin-right 1em
</style>
